const episodes = [
  {
    title: 'Episode 1 - The man pages introduction using ls',
    description:
      'This episode covers a brief introduction to the UNIX manual, commonly known as "man pages", using the "ls" command, which is a command used for displaying the contents of directories or folders on the system.',
    episodeNumber: 1,
    videoUrl: '1 - the man pages - introduction using the ls command.mp4',
    free: true,
    tags: ['man', 'help', 'ls', 'unix', 'osx', 'apple', 'mac', 'shell'],
  },
  {
    title: 'Episode 2 - The man pages user specified arguments',
    description:
      'Reviewing the man pages for the "ls" command shows how you can pass user specified arguments to that command.  Passing user specified arguments is not only available for the "ls" command, but for most commands available on the UNIX operating system.',
    episodeNumber: 2,
    videoUrl: '2 - the man pages - user specified arguments.mp4',
    free: true,
    tags: [
      'man',
      'help',
      'ls',
      'unix',
      'osx',
      'apple',
      'mac',
      'shell',
      'user',
      'specified',
      'arguments',
    ],
  },
  {
    title: 'Episode 3 - The man pages mutually exclusive arguments',
    description:
      'This episode shows how the man pages can specify mutually exclusive arguments for a given command.  For example, the "pwd" command accepts -L or -P options, but because they each return a unique result, using them together might not return the expected results.',
    episodeNumber: 3,
    videoUrl: '3 - the man pages - mutually exclusive arguments.mp4',
    free: true,
    tags: [
      'man',
      'help',
      'ls',
      'unix',
      'osx',
      'apple',
      'mac',
      'shell',
      'mutually exclusive',
      'arguments',
      'pwd',
    ],
  },
  {
    title: 'Episode 4 - The man pages important sections',
    description:
      'This episode shows the various sections that are provided for UNIX system manual, as well as how to access help from individual sections, and how the information returned might differ depending on which section was specified.',
    episodeNumber: 4,
    videoUrl: '4 - the man pages - important sections.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'man',
    ],
  },
  {
    title: 'Episode 5 - The whatis command introduction',
    description:
      'This episode shows how the "whatis" command will return all related commands for a keyword specified as an argument.',
    episodeNumber: 5,
    videoUrl: '5 - getting help - whatis command introduction.mp4',
    free: true,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'whatis',
    ],
  },
  {
    title: 'Episode 6 - The apropos command introduction',
    description:
      'This episode describes how the "apropos" command is similar to "whatis", but returns more data due to the data sources that it uses to search for keywords.',
    episodeNumber: 6,
    videoUrl: '6 - getting help - apropos command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'apropos',
    ],
  },
  {
    title: 'Episode 7 - The help command introduction',
    description:
      'This episode shows how the "help" command can be used to get more details on specific built-in commands provided by the bash shell.',
    episodeNumber: 7,
    videoUrl: '7 - getting help - help command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'help',
    ],
  },
  {
    title: 'Episode 8 - The info command introduction',
    description:
      'This episode shows how the "info" command can be used as an alternative source of system information to using the man pages, and how navigating that system will be more familiar to Emacs users.',
    episodeNumber: 8,
    videoUrl: '8 - getting help - info command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'info',
    ],
  },
  {
    title: 'Episode 9 - Directory hierarchy introduction',
    description:
      'This episode shows a brief introduction of how a UNIX filesystem is organized into a tree-like structure.',
    episodeNumber: 9,
    videoUrl: '9 - managing files and directories - hierarchy introduction.mp4',
    free: true,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 10 - Pathnames and ls',
    description:
      'This episode describes what Pathnames are, how they\'re constructed and how we can use a variety of flags with the "ls" command to get information about specific Pathnames.',
    episodeNumber: 10,
    videoUrl: '10 - managing files and directories - pathnames and ls.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'pathnames',
    ],
  },
  {
    title: 'Episode 11 - File types',
    description:
      'This episode describes the various file types available on UNIX, such as directories, symbolic links and device files, as well as the letters or symbols used to identify those file types using the "ls" command.',
    episodeNumber: 11,
    videoUrl: '11 - managing files and directories - file types.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'apple',
      'osx',
      'shell',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'hack',
      'command',
      'block',
      'device',
      'ls',
    ],
  },
  {
    title: 'Episode 12 - The touch command introduction',
    description:
      'This episode shows how you can quickly create empty or zero-length files using the "touch" command.',
    episodeNumber: 12,
    videoUrl: '12 - managing files and directories - the touch command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'touch',
    ],
  },
  {
    title: 'Episode 13 - Wildcard basics',
    description:
      'This episode covers the wildcards for the asterisk (or star), the question mark and square brackets, and how those patterns can be expanded to return more complex names for files and directories.',
    episodeNumber: 13,
    videoUrl: '13 - managing files and directories - wildcard basics.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'wildcard',
    ],
  },
  {
    title: 'Episode 14 - Making directories',
    description:
      'This episode shows how to use the "mkdir" command to create a directory.',
    episodeNumber: 14,
    videoUrl: '14 - managing files and directories - making directories with mkdir.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'directory',
    ],
  },
  {
    title: 'Episode 15 - Navigating directories',
    description:
      'This episode shows how to navigate or change into directories using the "cd" command.',
    episodeNumber: 15,
    videoUrl: '15 - managing files and directories - navigating directories with cd.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'directory',
    ],
  },
  {
    title: 'Episode 16 - Special directories',
    description:
      'This episode shows two special directories that are part of each directory under the root.',
    episodeNumber: 16,
    videoUrl: '16 - managing files and directories - special directories.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'directory',
    ],
  },
  {
    title: 'Episode 17 - The HOME directory',
    description:
      'This episode shows how a users HOME directory is represented using a tilde character, and how to navigate with the "cd" command to that directory, as well as how to return to the previous directory with the dash character.',
    episodeNumber: 17,
    videoUrl: '17 - managing files and directories - the HOME directory.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'HOME',
    ],
  },
  {
    title: 'Episode 18 - The nano editor introduction',
    description:
      'This episode shows how to open files using "nano", a basic text editor available on my UNIX systems, as well as how to access the editor\'s help system so that other operations can be performed such as saving content and exiting the editor.',
    episodeNumber: 18,
    videoUrl: '18 - managing files and directories - nano editor introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'nano',
    ],
  },
  {
    title: 'Episode 19 - The cat command introduction',
    description:
      'This episode shows how to view the contents of a file using the "cat" command.',
    episodeNumber: 19,
    videoUrl: '19 - managing files and directories - cat command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'cat',
    ],
  },
  {
    title: 'Episode 20 - The more & less commands introduction',
    description:
      'This episode shows how to view a file and page through the contents of it using either the "more" or "less" commands.',
    episodeNumber: 20,
    videoUrl: '20 - managing files and directories - more and less commands introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'less',
    ],
  },
  {
    title: 'Episode 21 - The head command introduction',
    description:
      'This episode shows how the "head" command can be used to show the first few lines of a given file.',
    episodeNumber: 21,
    videoUrl: '21 - managing files and directories - head command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'head',
    ],
  },
  {
    title: 'Episode 22 - The tail command introduction',
    description:
      'This episode shows how to display the last few lines of any file using the "tail" command.',
    episodeNumber: 22,
    videoUrl: '22 - managing files and directories - tail command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tail',
    ],
  },
  {
    title: 'Episode 23 - The which, whereis & file commands introduction',
    description:
      'This episode shows how to determine the full pathname for a given command using the "which" and "whereis" commands. Also, the "file" command is covered so that you can determine what type of file is located at a given pathname.',
    episodeNumber: 23,
    videoUrl: '23 - managing files and directories - which, whereis and file commands introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'whereis',
    ],
  },
  {
    title: 'Episode 24 - Copying files',
    description:
      'This episode shows several examples of using the "cp" command to copy files.',
    episodeNumber: 24,
    videoUrl: '24 - managing files and directories - copying files.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'copying',
    ],
  },
  {
    title: 'Episode 25 - Moving files',
    description: 'This episode shows how to move files using the "mv" command.',
    episodeNumber: 25,
    videoUrl: '25 - managing files and directories - moving files.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'moving files',
    ],
  },
  {
    title: 'Episode 26 - Removing files',
    description:
      'This episode shows how to remove files and directories using wildcard patterns.',
    episodeNumber: 26,
    videoUrl: '26 - managing files and directories - removing files.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'removing',
    ],
  },
  {
    title: 'Episode 27 - Removing directories',
    description:
      'This episode shows how the "rmdir" command can remove empty directories.',
    episodeNumber: 27,
    videoUrl: '27 - managing files and directories - removing directories.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'removing',
    ],
  },
  {
    title: 'Episode 28 - The grep command introduction',
    description:
      'This episode demonstrates how to search for files containing a specific keyword using the "grep" command, as well as some of the more useful flags that can be used in conjunction with the command.',
    episodeNumber: 28,
    videoUrl: '28 - managing files and directories - grep command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'grep',
    ],
  },
  {
    title: 'Episode 29 - The find command introduction',
    description:
      'This episode shows how use the "find" command to search for files containing various characteristics such as size, name, type and permissions, as well as how to execute separate commands for all files matching the results of "find".',
    episodeNumber: 29,
    videoUrl: '29 - managing files and directories - find command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'find',
    ],
  },
  {
    title: 'Episode 30 - Permissions introduction',
    description:
      'This episode discusses the basics of how permissions work in UNIX and how they are divided into distinct classes for Users, Groups and everyone else.',
    episodeNumber: 30,
    videoUrl: '30 - understanding directory and file permissions - introduction.mp4',
    free: true,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 31 - Symbolic notation',
    description:
      'This episode describes the symbols used by the "chmod" command to manipulate file and directory permissions in UNIX.',
    episodeNumber: 31,
    videoUrl: '31 - understanding directory and file permissions - symbolic notation.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'chmod',
    ],
  },
  {
    title: 'Episode 32 - Read permissions: file owner',
    description:
      'This episode shows how to use the "chmod" command to manipulate read permissions for the owner of a file.',
    episodeNumber: 32,
    videoUrl: '32 - understanding directory and file permissions - read permissions for file owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 33 - Write permissions: file owner',
    description:
      'This episode shows how to use the "chmod" command to manipulate write permissions for the owner of a file.',
    episodeNumber: 33,
    videoUrl: '33 - understanding directory and file permissions - write permissions for file owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 34 - Execute permissions: file owner',
    description:
      'This episode shows how to use the "chmod" command to manipulate execute permissions for the owner of a file.',
    episodeNumber: 34,
    videoUrl: '34 - understanding directory and file permissions - execute permissions for file owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 35 - Read permissions: directory owner',
    description:
      'This episode shows how to use the "chmod" command to manipulate read permissions for the owner of a directory.',
    episodeNumber: 35,
    videoUrl: '35 - understanding directory and file permissions - read permissions for directory owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 36 - Write permissions: directory owner',
    description:
      'This episode shows how to use the "chmod" command to manipulate write permissions for the the owner of a directory.',
    episodeNumber: 36,
    videoUrl: '36 - understanding directory and file permissions - write permissions for directory owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 37 - Execute permissions: directory owner',
    description:
      'This episode shows how to use the "chmod" command to remove execute permissions for the owner of a directory.',
    episodeNumber: 37,
    videoUrl: '37 - understanding directory and file permissions - execute permissions for directory owner.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 38 - Combinations of symbolic notation',
    description:
      'This episode shows how we can comma-delimit permissions for the various classes when using the "chmod" command.',
    episodeNumber: 38,
    videoUrl: '38 - understanding directory and file permissions - combinations of symbolic notation.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 39 - The X flag for a file',
    description:
      'This episode shows how applying the X flag with the "chmod" command can be used as a shortcut for setting execute permissions for a file.',
    episodeNumber: 39,
    videoUrl: '39 - understanding directory and file permissions - the X flag for a file.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 40 - The X flag for a directory',
    description:
      'This episode shows how to apply the X flag when using the "chmod" command to specify execute permissions for a directory.',
    episodeNumber: 40,
    videoUrl: '40 - understanding directory and file permissions - the X flag for a directory.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 41 - The setuid flag',
    description:
      'This episode shows how to use the "chmod" command to apply the setuid flag (or bit) for the owner of a file.',
    episodeNumber: 41,
    videoUrl: '41 - understanding directory and file permissions - setuid.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 42 - The setgid flag',
    description:
      'This episode shows how to use the "chmod" command to apply the setgid flag (or bit) to the group of a file.',
    episodeNumber: 42,
    videoUrl: '42 - understanding directory and file permissions - setgid.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 43 - The sticky bit: introduction',
    description:
      'This episode describes the sticky bit as a method for allowing directories to be append-only.',
    episodeNumber: 43,
    videoUrl: '43 - understanding directory and file permissions - sticky bit introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 44 - The sticky bit: write perms & owns file',
    description:
      'This episode shows how files can be deleted from directories when the sticky bit is set, provided that the proper ownership and permissions are already in place.',
    episodeNumber: 44,
    videoUrl: '44 - understanding directory and file permissions - sticky bit - user has write permission on directory and owns file.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 45 - The sticky bit: write perms but not owner',
    description:
      "This episode shows how files cannot be deleted from directories when the sticky bit is set when the file's owner is not the same as the current user.",
    episodeNumber: 45,
    videoUrl: '45 - understanding directory and file permissions - sticky bit - user has write permission on directory and does not own file.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 46 - The sticky bit: without execute permissions',
    description:
      'This episode shows how the sticky bit changes depending on whether the execute permissions are set or not for a directory.',
    episodeNumber: 46,
    videoUrl: '46 - understanding directory and file permissions - sticky bit - directory does not have execute permissions.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 47 - Special attributes',
    description:
      'This episode shows how extended attributes can be identified for a directory and how that information can be obtained using the "xattr" command.',
    episodeNumber: 47,
    videoUrl: '47 - understanding directory and file permissions - special attributes.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 48 - chmod introduction',
    description:
      'This episode describes how the "chmod" command can use an alternative format to "symbolic notation" for specifying permissions.  That alternative format is called "numeric notation" and a brief example is provided to compare the two formats.',
    episodeNumber: 48,
    videoUrl: '48 - understanding directory and file permissions - chmod numeric notation - introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 49 - chmod decimal to binary',
    description:
      'This episode describes a brief introduction to the binary numbering system and how it applies to file or directory permissions.',
    episodeNumber: 49,
    videoUrl: '49 - understanding directory and file permissions - chmod numeric notation - converting from decimal to binary.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 50 - chmod binary to decimal',
    description:
      'This episode shows examples of files and directories with symbolic notation and how those same permissions can be applied using the "chmod" command while using numeric notation.',
    episodeNumber: 50,
    videoUrl: '50 - understanding directory and file permissions - chmod numeric notation - converting from binary to decimal.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 51 - chmod special flags',
    description:
      'This episode describes the numeric values that are assigned for the setuid, setgid and sticky bits.',
    episodeNumber: 51,
    videoUrl: '51 - understanding directory and file permissions - chmod numeric notation - special flags - introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 52 - chmod setuid',
    description:
      'This episode shows how to use the "chmod" command to apply the setuid flag to a file using numeric notation.',
    episodeNumber: 52,
    videoUrl: '52 - understanding directory and file permissions - chmod numeric notation - setuid.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 53 - chmod setgid',
    description:
      'This episode shows how to use the "chmod" command to apply the setgid bit to a file using numeric notation.',
    episodeNumber: 53,
    videoUrl: '53 - understanding directory and file permissions - chmod numeric notation - setgid.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 54 - chmod sticky bit',
    description:
      'This episode shows how to use the "chmod" command to set the sticky bit for a directory using numeric notation.',
    episodeNumber: 54,
    videoUrl: '54 - understanding directory and file permissions - chmod numeric notation - sticky bit.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'permissions',
    ],
  },
  {
    title: 'Episode 55 -Streams introduction',
    description:
      'This episode introduces the 3 common file descriptors in UNIX: standard input, standard output, and standard error.',
    episodeNumber: 55,
    videoUrl: '55 - redirection and pipes - unix streams introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'descriptor',
    ],
  },
  {
    title: 'Episode 56 - Using cat to input data',
    description:
      'This episode shows how you can input data using the "cat" command, and how using the Control-D character is used to signal that you\'re done inputting data.',
    episodeNumber: 56,
    videoUrl: '56 - redirection and pipes - using cat to input data.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'cat',
    ],
  },
  {
    title: 'Episode 57 - Redirect stdout',
    description:
      'This episode shows an example of redirecting the standard output, or stdout, of a command to a file.',
    episodeNumber: 57,
    videoUrl: '57 - redirection and pipes - redirect stdout from a command to a file.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'stdout',
    ],
  },
  {
    title: 'Episode 58 - Using cat with multiple arguments',
    description:
      'The episode shows how to pass multiple file arguments to the "cat" command to concatenate the content of those files and redirect its standard output, or stdout, to a new file.',
    episodeNumber: 58,
    videoUrl: '58 - redirection and pipes - using cat with multiple file arguments.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'arguments',
    ],
  },
  {
    title: 'Episode 59 - Redirect with > can overwrite',
    description:
      'This episode shows how redirecting standard output with > can overwrite existing files.',
    episodeNumber: 59,
    videoUrl: '59 - redirection and pipes - redirect with > can create but also overwrite.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'redirect',
    ],
  },
  {
    title: 'Episode 60 - Redirect stdout with >>',
    description:
      'This episode shows how to redirect standard output to an existing file, but instead of overwriting the contents, the data is appended.',
    episodeNumber: 60,
    videoUrl: '60 - redirection and pipes - redirect stdout with >>.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'append',
    ],
  },
  {
    title: 'Episode 61 - Redirect stdout of multiple files',
    description:
      'This episode shows how to pass multiple file arguments to the "cat" command and redirect its standard output using the append operator.',
    episodeNumber: 61,
    videoUrl: '61 - redirection and pipes - redirect stdout of multiple files.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'append',
    ],
  },
  {
    title: 'Episode 62 - Redirect stdin with',
    description:
      'This episode shows how to redirect the standard input of a file into commands such as "mail" and "cat".',
    episodeNumber: 62,
    videoUrl: '62 - redirection and pipes - redirect stdin with <.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'input',
    ],
  },
  {
    title: 'Episode 63 - Redirect stdin then stdout',
    description:
      'This episode shows how to pass a file as standard input to the "sort" command and then redirect the resulting standard output to a separate file.',
    episodeNumber: 63,
    videoUrl: '63 - redirection and pipes - redirect stdin then stdout.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'input',
    ],
  },
  {
    title: 'Episode 64 - Redirect stdout and stderr',
    description:
      'This episode shows different notations for redirecting standard output and standard error at the same time.',
    episodeNumber: 64,
    videoUrl: '64 - redirection and pipes - redirect stdout and stderr.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'redirection',
    ],
  },
  {
    title: 'Episode 65 - Redirect stdout & stderr to separate locations',
    description:
      'This episode shows how to redirect standard output to a file and an alternate syntax for redirecting standard error to a separate file.',
    episodeNumber: 65,
    videoUrl: '65 - redirection and pipes - redirect stdout and stderr to separate locations.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'output',
    ],
  },
  {
    title: 'Episode 66 - Redirect stderr to nowhere',
    description:
      'This episode shows how to redirect standard error to a null device, a bit bucket located at /dev/null.',
    episodeNumber: 66,
    videoUrl: '66 - redirection and pipes - redirect stderr to nowhere.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'null',
    ],
  },
  {
    title: 'Episode 67 - Here document',
    description:
      'This episode demonstrates how to create a "here" document, a temporary document that maintains formatting, expands environment variable names and returns the results of commands being run inside it.',
    episodeNumber: 67,
    videoUrl: '67 - redirection and pipes - here document.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'here',
    ],
  },
  {
    title: 'Episode 68 - Pipes introduction',
    description:
      'This episode covers the basics of using pipes, which is a conventional approach in UNIX for single commands to be chained together to create more powerful tools.',
    episodeNumber: 68,
    videoUrl: '68 - redirection and pipes - pipes introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'pipes',
    ],
  },
  {
    title: 'Episode 69 - Top 10 unix commands',
    description:
      'This episode shows a practical example for how pipes can be used to analyze your command usage. A wide number of commands are used, including "history", "cut", "sort", "uniq" and "head".',
    episodeNumber: 69,
    videoUrl: '69 - redirection and pipes - top 10 unix commands.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'pipes',
    ],
  },
  {
    title: 'Episode 70 - The tee command',
    description:
      'This episode shows how to use the "tee" command to send standard output to the terminal and a separate file at the same time.',
    episodeNumber: 70,
    videoUrl: '70 - redirection and pipes - the tee command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tee',
    ],
  },
  {
    title: 'Episode 71 - The ps command introduction',
    description:
      'This episode provides a basic introduction to UNIX processes and a few ways to view them using the "ps" command.',
    episodeNumber: 71,
    videoUrl: '71 - working with processes - the ps command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 72 - The top command introduction',
    description:
      'This episode shows how the "top" command can display a system\'s process list in real time.',
    episodeNumber: 72,
    videoUrl: '72 - working with processes - the top command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 73 - The top command help menu',
    description:
      'This episode shows how to access the help menu for the "top" command, and how that menu provides ways for sorting the output of the command as well as send specific signals to processes.',
    episodeNumber: 73,
    videoUrl: '73 - working with processes - the top command help menu.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 74 - The top command sorting output',
    description:
      'This episode shows how the "top" command allows output to be sorted by key name, such as cpu and many others.',
    episodeNumber: 74,
    videoUrl: '74 - working with processes - the top command sorting output.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 75 - The top command update interval',
    description:
      'This episode shows how the "top" command\'s output is updated every second by default, but that the interval for displaying the output can be changed.',
    episodeNumber: 75,
    videoUrl: '75 - working with processes - the top command update interval.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 76 - The top command sending signals',
    description:
      'This episode shows how to send a TERM or "terminate" signal to a process from the "top" command\'s user interface.',
    episodeNumber: 76,
    videoUrl: '76 - working with processes - the top command sending signals.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 77 - The top command specified options',
    description:
      'This episode shows how the "top" command can accept option arguments on the command line for a variety of features such as sorting output by key name, as well as how often in seconds the output should be updated.',
    episodeNumber: 77,
    videoUrl: '77 - working with processes - the top command specified options.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 78 - Foreground processes introduction',
    description:
      'This episode describes foreground processes and how they can be terminated or suspended using different key stroke combinations.',
    episodeNumber: 78,
    videoUrl: '78 - working with processes - foreground processes introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'processes',
    ],
  },
  {
    title: 'Episode 79 - The jobs command',
    description:
      'This episode shows how use the bash shell "jobs" builtin command for listing the active jobs on the system.',
    episodeNumber: 79,
    videoUrl: '79 - working with processes - the jobs command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'jobs',
    ],
  },
  {
    title: 'Episode 80 - Background jobs introduction',
    description:
      'This episode shows how to use an ampersand symbol to run commands in the background.',
    episodeNumber: 80,
    videoUrl: '80 - working with processes - background jobs introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'jobs',
    ],
  },
  {
    title: 'Episode 81 - The bg command introduction',
    description:
      'This episode shows how to use the "bg" command to send running processes in the current terminal to the background.',
    episodeNumber: 81,
    videoUrl: '81 - working with processes - the bg command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'jobs',
    ],
  },
  {
    title: 'Episode 82 - The fg command introduction',
    description:
      'This episode shows how to use the bash shell "fg" builtin command to bring background processes to the foreground.',
    episodeNumber: 82,
    videoUrl: '82 - working with processes - the fg command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'jobs',
    ],
  },
  {
    title: 'Episode 83 - The kill command introduction',
    description:
      'This episode shows how to use the "kill" command to send signals to a process, which can be done using signal names or numbers, process ids or job specs.',
    episodeNumber: 83,
    videoUrl: '83 - working with processes - the kill command.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'signals',
    ],
  },
  {
    title: 'Episode 84 - The curl command introduction',
    description:
      'This episode shows an example of using the "curl" command to download the Pow web server.',
    episodeNumber: 84,
    videoUrl: '84 - installing software from the command line - the curl command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'curl',
    ],
  },
  {
    title: 'Episode 85 - Package management: brew introduction',
    description:
      'This episode provides a brief introduction to "brew", which a package management system available for OSX, with details on how to access its help documentation as well as how to install brew from the command line.',
    episodeNumber: 85,
    videoUrl: '85 - installing software from the command line - package management with brew - introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'package',
    ],
  },
  {
    title: 'Episode 86 - Package management: brew commands',
    description:
      'This episode covers the most common commands used by the "brew" package management system, such as doctor, outdated, missing and several others.',
    episodeNumber: 86,
    videoUrl: '86 - installing software from the command line - package management with brew - various commands.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'package',
    ],
  },
  {
    title: 'Episode 87 - The zip & unzip commands introduction',
    description:
      'This episode shows the basics of using the "zip" and "unzip" commands for creating and extracting compressed archive files containing the .zip file suffix.',
    episodeNumber: 87,
    videoUrl: '87 - installing software from the command line - the zip and unzip commands introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'archive',
    ],
  },
  {
    title: 'Episode 88 - The gzip command introduction',
    description:
      'This episode shows how to use the "gzip" command to compress a file using the namesake file suffix.',
    episodeNumber: 88,
    videoUrl: '88 - installing software from the command line - the gzip command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'archive',
    ],
  },
  {
    title: 'Episode 89 - The gunzip command introduction',
    description:
      'This episode shows how to use the "gunzip" command to decompress a file that is in the gzip file format.',
    episodeNumber: 89,
    videoUrl: '89 - installing software from the command line - the gunzip command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'archive',
    ],
  },
  {
    title: 'Episode 90 - The tar command: introduction',
    description:
      'This episode introduces the tar command and how it can be used to list as well as extract the contents of an archive with a .tar file suffix.',
    episodeNumber: 90,
    videoUrl: '90 - installing software from the command line - the tar command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 91 - The tar command: creating archives',
    description:
      'This episode shows how to create a tar archive using more than one file.',
    episodeNumber: 91,
    videoUrl: '91 - installing software from the command line - the tar command - creating archives.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 92 - The tar command: creating a gzipped archive',
    description:
      'This episode shows how to use the tar command to create an archive that is compressed using gzip.',
    episodeNumber: 92,
    videoUrl: '92 - installing software from the command line - the tar command - creating a gzipped tar archive.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 93 - The tar command: creating a tgz archive',
    description:
      'This episode shows how to create files with a .tgz suffix and how to decompress it using gunzip.',
    episodeNumber: 93,
    videoUrl: '93 - installing software from the command line - creating a tgz archive.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 94 - The tar command: extracting a gzipped archive',
    description:
      'This episode shows how to extract the contents of files with a .tar.gz or .tgz suffix.',
    episodeNumber: 94,
    videoUrl: '94 - installing software from the command line - extracting a gzipped tar archive.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'tar',
    ],
  },
  {
    title: 'Episode 95 - The compress & uncompress commands introduction',
    description:
      'This episode demonstrates how to manage files with a .Z suffix by using the compress and uncompress commands.',
    episodeNumber: 95,
    videoUrl: '95 - installing software from the command line - the compress and uncompress commands.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'compress',
    ],
  },
  {
    title: 'Episode 96 - Installing software from source',
    description:
      'This episode shows how to install software from the Tcl source package using the configure, make & make install commands.',
    episodeNumber: 96,
    videoUrl: '96 - installing software from the command line - installing from source.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'zsh',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
    ],
  },
  {
    title: 'Episode 97 - The env command introduction',
    description:
      "This episode shows how to display the user's environment variables and highlights some of the more common ones that are used.",
    episodeNumber: 97,
    videoUrl: '97 - features of a unix shell - the env command introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'env',
    ],
  },
  {
    title: 'Episode 98 - The PATH environment variable',
    description:
      'This episode explains what the PATH environment variable does and how to view its contents using the "echo" command.',
    episodeNumber: 98,
    videoUrl: '98 - features of a unix shell - the PATH environment variable.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'PATH',
    ],
  },
  {
    title: 'Episode 99 - Setting environment variables',
    description:
      'After putting a "hello-world" shell script under my ~/bin/ directory, I show how to set the PATH variable using the "export" built-in command so that I can run the script without specifying the full path location.',
    episodeNumber: 99,
    videoUrl: '99 - features of a unix shell - setting environment variables.mp4',
    free: false,
    tags: [
      'export',
      'environment',
      'variables',
      'PATH',
      'unix',
      'osx',
      'apple',
      'mac',
      'shell',
    ],
  },
  {
    title: 'Episode 100 - Displaying environment variables',
    description:
      'This episode shows how to use the "echo" command to display the contents of common environment variables.',
    episodeNumber: 100,
    videoUrl: '100 - features of a unix shell - displaying environment variables.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'environment',
    ],
  },
  {
    title: 'Episode 101 - Shell initialization files',
    description:
      'This episode discusses how certain files are run when loading the bash shell, and how a simple trick can be used to eliminate some of the complexity with your .bashrc and .bash_profile files.',
    episodeNumber: 101,
    videoUrl: '101 - features of a unix shell - shell initialization files introduction.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'login',
    ],
  },
  {
    title: 'Episode 102 - Sourcing files',
    description:
      'This episode shows how to use the bash shell "source" builtin command to make it a part of the current shell environment.',
    episodeNumber: 102,
    videoUrl: '102 - features of a unix shell - sourcing files.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'source',
    ],
  },
  {
    title: 'Episode 103 - The alias & unalias commands',
    description:
      'This episode shows how to disable an existing alias as well as how to list all aliases currently available in your environment.',
    episodeNumber: 103,
    videoUrl: '103 - features of a unix shell - the unalias and alias commands.mp4',
    free: false,
    tags: [
      'unix',
      'devops',
      'programming',
      'software',
      'linux',
      'apple',
      'osx',
      'script',
      'shell',
      'mac',
      'bash',
      'network',
      'internet',
      'system',
      'administration',
      'security',
      'hack',
      'command',
      'alias',
    ],
  }
];

export default episodes;

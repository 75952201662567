// src/index.js
import "bootstrap/dist/css/bootstrap.css"
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import React from "react"
import { hydrate, render } from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

const rootElement = document.getElementById("root")

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

serviceWorker.unregister()

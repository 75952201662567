// src/App.js
import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import "./App.scss"
import config from "./auth_config.json"
import { Auth0Provider } from "./react-auth0-spa"
import history from "./utils/history"
import episodes from "./data/episodes"
import { EpisodeProvider } from "./EpisodeContext"
import prerenderedLoadable from "./utils/prerenderedLoadable"

const redirect_uri = `${window.location.origin}/app`
// Public components
const LayoutPublic = prerenderedLoadable(() =>
  import("./components/LayoutPublic")
)
const Home = prerenderedLoadable(() => import("./components/Home"))
const Episodes = prerenderedLoadable(() => import("./components/Episodes"))
const Newsletters = prerenderedLoadable(() =>
  import("./components/Newsletters")
)
const Support = prerenderedLoadable(() => import("./components/Support"))
const NotFound = prerenderedLoadable(() => import("./components/NotFound"))
const ErrorBoundary = prerenderedLoadable(() =>
  import("./components/ErrorBoundary")
)
// Private components
const LayoutPrivate = prerenderedLoadable(() =>
  import("./components/LayoutPrivate")
)
const PrivateRoute = prerenderedLoadable(() =>
  import("./components/PrivateRoute")
)
const Profile = prerenderedLoadable(() => import("./components/Profile"))
const SignUp = prerenderedLoadable(() => import("./components/SignUp"))
const VideoPlayer = prerenderedLoadable(() =>
  import("./components/VideoPlayer")
)
const VideoPosterWithoutPlayer = prerenderedLoadable(() =>
  import("./components/VideoPosterWithoutPlayer")
)
const freeEpisodes = [1, 2, 3, 5, 9, 30]
const episodeNumbers = episodes.map((e) => e.episodeNumber)

// A function that routes the user to the right place after login
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

function App() {
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={redirect_uri}
      onRedirectCallback={onRedirectCallback}
    >
      <div className="App">
      <ErrorBoundary>
      <EpisodeProvider value={{ episodes, episodeNumbers }}>
      {/* Don't forget to include the history module */}
      <Router history={history}>
      <Switch>
      <Route
      path="/"
      exact
      render={(props) => (
          <LayoutPublic>
          <Home {...props} />
          </LayoutPublic>
          )}
    />
      <Route
      path="/episodes/all"
      exact
      render={(props) => (
          <LayoutPublic>
          <Episodes {...props} />
          </LayoutPublic>
          )}
    />
      <Route
      path="/support"
      exact
      render={(props) => (
          <LayoutPublic>
          <Support {...props} />
          </LayoutPublic>
          )}
    />
      <Route
      path="/newsletters"
      exact
      render={(props) => (
          <LayoutPublic>
          <Newsletters {...props} />
          </LayoutPublic>
          )}
    />
    {episodeNumbers.map((n) => {
        const episode = episodes[Number(n) - 1]
        // Public path
        const path = `/episodes/${n}`
        // For public routes: /
        // VideoPlayer is used for all free episodes
        // VideoPosterWithoutPlayer is used for all private episodes (will have link to /app/episode/:id
        return (
            <Route
            path={path}
            exact
            key={n}
            render={(props) => {
            return (
                <LayoutPublic>
                {freeEpisodes.includes(n) ? (
                    <VideoPlayer {...props} episode={episode} />
                    ) : (
                      <VideoPosterWithoutPlayer
                      {...props}
                      episode={episode}
                      />
                      )}
                </LayoutPublic>
                )
            }}
            />
            )
                               })}
    {episodeNumbers.map((n) => {
        const episode = episodes[Number(n) - 1]
        // Protected by app authentication
        const appPath = `/app/episodes/${n}`
        // For private routes /app
        // VideoPlayer is used for all episodes
        return (
            <PrivateRoute
            path={appPath}
            exact
            key={n}
            render={(props) => (
                <LayoutPrivate>
                <VideoPlayer {...props} episode={episode} />
                </LayoutPrivate>
                )}
            />
            )
        // All public routes should have a private equivalent
        })}
    <Route
      path="/app/sign-up"
      exact
      render={(props) => (
          <ToastProvider>
          <LayoutPrivate>
          <SignUp {...props} />
          </LayoutPrivate>
          </ToastProvider>
          )}
    />
      <PrivateRoute
      path="/app/"
      exact
      render={(props) => (
          <LayoutPrivate>
          <Home {...props} />
          </LayoutPrivate>
          )}
    />
      <PrivateRoute
      path="/app/episodes/all"
      exact
      render={(props) => (
          <LayoutPrivate>
          <Episodes {...props} />
          </LayoutPrivate>
          )}
    />
      <PrivateRoute
      path="/app/support"
      exact
      render={(props) => (
          <LayoutPrivate>
          <Support {...props} />
          </LayoutPrivate>
          )}
    />
      <PrivateRoute
      path="/app/newsletters"
      exact
      render={(props) => (
          <LayoutPrivate>
          <Newsletters {...props} />
          </LayoutPrivate>
          )}
    />
      <PrivateRoute
      path="/app/profile"
      exact
      render={(props) => (
          <LayoutPrivate>
          <Profile {...props} />
          </LayoutPrivate>
          )}
    />
      <Route component={NotFound} />
      </Switch>
      </Router>
      </EpisodeProvider>
      </ErrorBoundary>
      </div>
    </Auth0Provider>
  )
}

export default App
